export var refBarChart = {
  labels: [
    "01 Nov",
    "02 Nov",
    "03 Nov",
    "04 Nov",
    "05 Nov",
    "06 Nov",
    "07 Nov",
    "08 Nov",
    "09 Nov",
    "10 Nov",
    "11 Nov",
    "12 Nov",
    "13 Nov",
    "14 Nov",
    "15 Nov",
    "16 Nov",
    "17 Nov",
    "18 Nov",
    "19 Nov",
    "20 Nov",
    "21 Nov",
    "22 Nov",
    "23 Nov",
    "24 Nov",
    "25 Nov",
    "26 Nov",
    "27 Nov",
    "28 Nov",
    "29 Nov",
    "30 Nov",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Join",
      backgroundColor: "#9cabff",
      borderWidth: 2,
      borderColor: "transparent",
      hoverBorderColor: "transparent",
      borderSkipped: "bottom",
      barPercentage: 0.5,
      categoryPercentage: 0.7,
      data: [
        110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95,
        75, 90, 75, 90,
      ],
    },
  ],
};

export var refBarChartSet2 = {
  labels: [
    "01 Nov",
    "02 Nov",
    "03 Nov",
    "04 Nov",
    "05 Nov",
    "06 Nov",
    "07 Nov",
    "08 Nov",
    "09 Nov",
    "10 Nov",
    "11 Nov",
    "12 Nov",
    "13 Nov",
    "14 Nov",
    "15 Nov",
    "16 Nov",
    "17 Nov",
    "18 Nov",
    "19 Nov",
    "20 Nov",
    "21 Nov",
    "22 Nov",
    "23 Nov",
    "24 Nov",
    "25 Nov",
    "26 Nov",
    "27 Nov",
    "28 Nov",
    "29 Nov",
    "30 Nov",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Join",
      backgroundColor: "#9cabff",
      borderWidth: 2,
      borderColor: "transparent",
      hoverBorderColor: "transparent",
      borderSkipped: "bottom",
      barPercentage: 0.5,
      categoryPercentage: 0.7,
      data: [
        90, 150, 105, 55, 95, 75, 90, 110, 80, 125, 105, 95, 25, 90, 110, 80, 125, 55, 105, 75, 40, 110, 150, 125, 55,
        45, 75, 90, 75, 150,
      ],
    },
  ],
};

export var refBarChartSet3 = {
  labels: [
    "01 Nov",
    "02 Nov",
    "03 Nov",
    "04 Nov",
    "05 Nov",
    "06 Nov",
    "07 Nov",
    "08 Nov",
    "09 Nov",
    "10 Nov",
    "11 Nov",
    "12 Nov",
    "13 Nov",
    "14 Nov",
    "15 Nov",
    "16 Nov",
    "17 Nov",
    "18 Nov",
    "19 Nov",
    "20 Nov",
    "21 Nov",
    "22 Nov",
    "23 Nov",
    "24 Nov",
    "25 Nov",
    "26 Nov",
    "27 Nov",
    "28 Nov",
    "29 Nov",
    "30 Nov",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Join",
      backgroundColor: "#9cabff",
      borderWidth: 2,
      borderColor: "transparent",
      hoverBorderColor: "transparent",
      borderSkipped: "bottom",
      barPercentage: 0.5,
      categoryPercentage: 0.7,
      data: [
        150, 90, 25, 115, 115, 75, 150, 30, 70, 135, 85, 55, 45, 90, 90, 70, 135, 75, 55, 55, 90, 120, 20, 135, 55, 95,
        75, 90, 55, 90,
      ],
    },
  ],
};

export var refBarChart4 = {
  labels: [
    "01 Nov",
    "02 Nov",
    "03 Nov",
    "04 Nov",
    "05 Nov",
    "06 Nov",
    "07 Nov",
    "08 Nov",
    "09 Nov",
    "10 Nov",
    "11 Nov",
    "12 Nov",
    "13 Nov",
    "14 Nov",
    "15 Nov",
    "16 Nov",
    "17 Nov",
    "18 Nov",
    "19 Nov",
    "20 Nov",
    "21 Nov",
    "22 Nov",
    "23 Nov",
    "24 Nov",
    "25 Nov",
    "26 Nov",
    "27 Nov",
    "28 Nov",
    "29 Nov",
    "30 Nov",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Join",
      backgroundColor: "#9cabff",
      borderWidth: 2,
      borderColor: "transparent",
      hoverBorderColor: "transparent",
      borderSkipped: "bottom",
      barPercentage: 0.5,
      categoryPercentage: 0.7,
      data: [
        78, 80, 105, 75, 55, 55, 50, 100, 110, 85, 75, 45, 55, 10, 110, 80, 125, 55, 95, 75, 90, 110, 70, 105, 125, 105,
        75, 90, 55, 120,
      ],
    },
  ],
};

export var profitCM = {
  labels: [
    "01 Nov",
    "02 Nov",
    "03 Nov",
    "04 Nov",
    "05 Nov",
    "06 Nov",
    "07 Nov",
    "08 Nov",
    "09 Nov",
    "10 Nov",
    "11 Nov",
    "12 Nov",
    "13 Nov",
    "14 Nov",
    "15 Nov",
    "16 Nov",
    "17 Nov",
    "18 Nov",
    "19 Nov",
    "20 Nov",
    "21 Nov",
    "22 Nov",
    "23 Nov",
    "24 Nov",
    "25 Nov",
    "26 Nov",
    "27 Nov",
    "28 Nov",
    "29 Nov",
    "30 Nov",
  ],
  dataUnit: "USD",
  datasets: [
    {
      label: "Send",
      borderColor: "#5d7ce0",
      backgroundColor: "rgba(93, 124, 224, 0.25)",
      lineTension: 0.4,
      borderWidth: 2,
      fill:true,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#5d7ce0",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [
        0, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95,
        75, 90, 75, 0,
      ],
    },
  ],
};
